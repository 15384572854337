import { XccEnvironment } from '@xcc-client/services';

export const environment: XccEnvironment = {
  envMode: 'prod',
  isProduction: true,
  stripeApiPublicKey: 'pk_live_wf9AJemdXR29ucoJRrOuDWEr00XeXeenvB',
  xccApiHost: 'https://checkout-api.ecom-prod.idrivesafely.com',
  xgritApiHostUrl: 'https://prd.xgritapi.com/api/1',
  xgritAuthorization: 'Basic MmM5MjBlOGYxOTI0ZWVjNzgxZWRjMzE1ZjgxZTQzYTE6',
  xgritWebAppUrl: 'https://app.idrivesafely.com/user/signin',
  brand: 'ids',
  domain: '.idrivesafely.com',
  clientHost: 'https://www.idrivesafely.com/',
  termsAndConditionsUrl: 'https://www.idrivesafely.com/terms-and-conditions/',
  recaptchaSiteKey: '6LcnfWMjAAAAAI7wMlWYTqnWRTivwx-bmDsmIzQL',
  stripeBetas: 'elements_enable_deferred_intent_beta_1',
  stripeApiVersion: '""',
  addPurchaseQueryParam: true,
  ambassador: {
    uid: 'a9bfbd56-9ab3-4e72-9f82-29436089bf30',
    isRecordConversionEnabled: true,
  },
  sentryDsn: 'https://de61c04409d4c88b402a4c484b7c4eb6@o1007654.ingest.sentry.io/4506360987320320',
  passwordConfig: {
    passwordRegEx: '^.{6,}$',
    passwordNotMatchMessage: 'Your password must be at least 6 characters long',
    showPasswordStrength: true,
  },
  temporaryDownurl: 'our-system-is-temporarily-down/',
};
